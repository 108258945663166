import React, { useState } from "react";
import { utils, write, read } from "xlsx";
import { saveAs } from "file-saver";
import "./App.css"; // Import CSS file for styling

const App = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [resultData, setResultData] = useState([]);

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };
  function convertDateFormat(inputDate) {
    const months = {
      JAN: "01",
      FEB: "02",
      MAR: "03",
      APR: "04",
      MAY: "05",
      JUN: "06",
      JUL: "07",
      AUG: "08",
      SEP: "09",
      OCT: "10",
      NOV: "11",
      DEC: "12",
    };

    const day = inputDate.substr(0, 2);
    const month = months[inputDate.substr(2, 3)];
    const year = `20${inputDate.substr(5, 2)}`;

    return `${day}-${month}-${year}`;
  }

  const handleUpload = async () => {
    setResultData([]);
    if (!pdfFile) {
      alert("Please select a PDF file first.");
      return;
    }

    const formData = new FormData();
    formData.append("dataFile", pdfFile);

    try {
      const response = await fetch(
        // "http://localhost:3333/upload/bsp_file",
        "https://apibsp.taysirsolutions.net/upload/bsp_file",
        {
          method: "POST",
          body: formData,
        }
      );
      console.log(response);
      const data = await response.json();
      setResultData(
        data.result.map((el) => {
          let _el = {};
          _el["CIE"] = el.cie;
          _el["N. ticket"] = el.ticketNumber;
          _el["Status"] = el.status;
          _el["Date d'emission"] = convertDateFormat(el.emmissionDate);
          _el["TTC"] =
            typeof el.totalTTC == "string" ? Number(el.totalTTC) : el.totalTTC;
          _el["Hors Tax"] =
            typeof el.fareBasis == "string"
              ? Number(el.fareBasis)
              : el.fareBasis;
          _el["Total Taxe"] =
            typeof el.totalTax == "string" ? Number(el.totalTax) : el.totalTax;
          _el["TAXE"] = typeof el.tax == "string" ? Number(el.tax) : el.tax;
          _el["F&C"] = typeof el.fc == "string" ? Number(el.fc) : el.fc;
          _el["% Commission"] =
            typeof el.agencyCommission == "string"
              ? Number(el.agencyCommission)
              : el.agencyCommission;
          _el["Prix à payer"] =
            typeof el.totalTicket == "string"
              ? Number(el.totalTicket)
              : el.totalTicket;
          return _el;
        })
      );
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file. Please try again later.");
    }
  };

  const handleDownload = () => {
    if (resultData.length === 0) {
      alert("No data to download.");
      return;
    }

    // Create worksheet
    const ws = utils.json_to_sheet(resultData);

    // Add styles
    ws["!cols"] = [
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    ws["!rows"] = [{ hpx: 30 }]; // Set row height

    // Add styles
    ws["!cols"] = [
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    ws["!rows"] = [{ hpx: 30 }]; // Set row height

    // Add cell styles for the header row
    const headerStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "FFFF00" } }, // Yellow background
    };

    Object.keys(ws).forEach((cell) => {
      if (cell.match(/^[A-Z]+1$/)) {
        // Check if cell is in the first row
        ws[cell].s = headerStyle;
      }
    });
    // Add cell styles for the header row

    // Convert worksheet to workbook
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file
    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(excelBlob, pdfFile.name + ".xlsx");
  };

  return (
    <div className="container">
      <h1>Upload PDF File</h1>
      <input type="file" accept=".pdf" onChange={handleFileChange} />
      <button className="upload-button" onClick={handleUpload}>
        Upload
      </button>
      <br></br>
      <button className="download-button" onClick={handleDownload}>
        Download Result as Excel
      </button>
      <h2>Example Result :</h2>
      <table style={{ margin: "20px auto" }}>
        <thead>
          {resultData &&
            resultData[0] &&
            Object.keys(resultData[0]).map((k) => <th key={k}>{k}</th>)}
        </thead>
        {resultData.slice(0, 1000).map((item, index) => (
          <tr key={index}>
            {Object.keys(item).map((k) => (
              <td key={k}>{item[k]}</td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );
};

export default App;
